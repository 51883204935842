import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import CreatePhones from './pages/CreatePhones';
import EditPhones from './pages/EditPhones';
import Phone from './pages/Phone';
import Messages from './pages/Messages';
import PhoneQr from './pages/PhoneQr';
import Login from './login/login';
import CreateUser from './pages/CreateUser';
import { MenuProvider } from './components/MenuContext';
import AuthProvider from 'react-auth-kit';
import AuthOutlet from '@auth-kit/react-router/AuthOutlet';
import NavMenu from './components/NavMenu';
import FullLogo from './images/FullLogo';
import createStore from 'react-auth-kit/createStore';
import Users from './pages/Users';
import { Helmet } from 'react-helmet';
// In your JavaScript/React file
//import bootstrap js
import "bootstrap/dist/js/bootstrap.bundle.min.js";

function App() {
  const store = createStore({
    authName: '_auth',
    authType: 'cookie',
    cookieDomain: window.location.hostname,
    cookieSecure: window.location.protocol === 'https:',
    tokenType: 'Bearer'
  });    
  return (
    <AuthProvider store={store}>
      <Helmet>  
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="Api para comunicación a través de Whatsapp" />
        <meta name="keywords" content="Whatsapp, api, whatsapp integration" />
        <meta name="author" content="Whappi" />
        <meta property="og:title" content="Whappi" />
        <meta property="og:description" content="Api para comunicación a través de Whatsapp" />
        <meta property="og:image" content="/icon.png" />
        <meta property="og:url" content="https://bambootechstudio.com" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="Whatsapp Integration API" />
        <meta name="twitter:title" content="Whappi, comunicación por whatsapp" />
        <meta name="twitter:description" content="Api para comunicación por whatsapp" />
        <meta name="twitter:image" content="/icon.png" />
        <title>Whappi - Communicate Now</title>

      </Helmet>
      <MenuProvider>
        <div className="App">      
          <Router>               
            <nav className="navbar fixed-top navbar-expand-lg bg-dark border-bottom" data-bs-theme="dark">
              <div className="container-fluid">
                <span className="navbar-brand"><FullLogo></FullLogo></span>              
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">            
                  <NavMenu />
                </div>
              </div>
            </nav>            
            <Routes>
              <Route path="/login" element={<Login/>} />
              <Route element={<AuthOutlet fallbackPath='/login' />}>
                <Route path="/" element={<Home/>} />
                <Route path="/createphone" element={<CreatePhones/>} />
                <Route path="/createuser" element={<CreateUser/>} />
                <Route path="/users" element={<Users/>} />
                <Route path="/editphone/:id" element={<EditPhones/>} />
                <Route path="/phone/:pid" element={<Phone/>} />
                <Route path="/messages/:chatId" element={<Messages/>} />                
              </Route>
              <Route path="/phoneQr/:pid" element={<PhoneQr/>} />
            </Routes>
          </Router>      
        </div>
      </MenuProvider>
    </AuthProvider>
  );
}

export default App;
