import React from 'react'
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import 'react-toastify/dist/ReactToastify.css';

function Home() {
    console.log('Home component rendering');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    const authHeader = useAuthHeader();
    console.log('Auth header:', authHeader);
    
    const [listOfPhones, setListOfPhones] = useState([]);
    let navigate = useNavigate();
    const notify = (message) => toast(message);

    const handleNavigate = (phone) => {
      const dataToPass = { phone: phone };      
      navigate(`/phone/${phone.id}`, { state: { passedData: dataToPass } });
    };

    const handleEdit = (phone) => {
      const dataToPass = { phone: phone };
      navigate(`/editphone/${phone.id}`, { state: { passedData: dataToPass } });
    };

    const handleDelete = (phone) => { 
      axios.delete(`${process.env.REACT_APP_API_URL}/phones/${phone.id}`, {
        headers: {
          'Authorization': authHeader
        }
      }).then((response) => {          
          notify('Phone deleted successfully');
          const updatedList = listOfPhones.filter(savedPhone => savedPhone.id !== phone.id);
          setListOfPhones(updatedList);
      })
      .catch((error) => {
        console.error('Delete error:', error);
        notify('Error deleting phone');
      });
    };

    useEffect(() => {
        console.log('useEffect running');
        setLoading(true);
        setError(null);

        if (!authHeader) {
          console.log('No auth header, redirecting to login');
          navigate('/login');
          return;
        }

        console.log('Fetching phones from:', process.env.REACT_APP_API_URL);
        axios.get(`${process.env.REACT_APP_API_URL}/phones`, {
          headers: {
            'Authorization': authHeader
          }
        })
        .then((response) => {   
          console.log('API response:', response.data);       
          setListOfPhones(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Fetch error:', error);
          setError(error.message || 'Error fetching phones');
          setLoading(false);
          if (error.response?.status === 401) {
            navigate('/login');
          }
        });    
    }, [authHeader, navigate]);

    console.log('Current state:', { loading, error, phonesCount: listOfPhones.length });

    if (loading) {
      return (
        <div className='container mt-5'>
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div className='container mt-5'>
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        </div>
      );
    }

    return (    
      <div className='container mt-5'>
        <h1>List of phones</h1>
        {listOfPhones.length === 0 ? (
          <div className="alert alert-info">
            No phones found
          </div>
        ) : (
          <table className='table table-striped'>
            <thead>          
              <tr>
                <th>Client</th>
                <th>Phone</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {listOfPhones.map((value, key) => (
                <tr key={key}>
                  <td>{value.client}</td>
                  <td>{value.phoneNumber}</td>
                  <td>{value.status ? 'activo' : 'inactivo'}</td>          
                  <td>            
                    <button className="btn btn-sm btn-primary"
                      onClick={() => handleNavigate(value)}>
                      <i className="material-icons">preview</i>
                    </button>
                    <button className="btn btn-sm btn-success"
                      onClick={() => handleEdit(value)}>                            
                      <i className="material-icons">edit</i>
                    </button>
                    <button className="btn btn-sm btn-danger"
                      onClick={() => handleDelete(value)}>                            
                      <i className="material-icons">delete_outline</i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"                
        />    
      </div>
    );
}

export default Home;