import React, {useEffect, useState} from 'react';
import { useNavigate,useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import QRCode from "react-qr-code";
import io from "socket.io-client";
import CopyToClipboardButton from '../components/CopyToClipboardButton';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let socket;
function getSocket(authHeader) {
    if (!socket) {
        socket = io.connect(`${process.env.REACT_APP_API_URL}`, {
            extraHeaders: {
                Authorization: authHeader
            },
            reconnection: true,
            reconnectionAttempts: 5,
            reconnectionDelay: 1000
        });
        console.log("Socket initialized with auth headers");
    }
    return socket;
}

function Phone() {
    let navigate = useNavigate();
    const authHeader = useAuthHeader();
    axios.defaults.headers.common = {'Authorization': `${authHeader}`};
    let { pid } = useParams();
    const location = useLocation();   
    const [phone,setPhone] = useState({});
    const [qrVisible, setQrVisible] = useState(false);
    const [token,setToken] = useState("");
    const [chats,setChats] = useState([]);
    const [clientStatus,setClientStatus] = useState("");
    const [isSpinning, setSpinning] = useState(false);
    const [qrCode,setQrCode] = useState("");        
    const [isConnected,setConnected] = useState(true);        
    const [receivedId, setReceivedId] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const currentUrl = window.location.origin;
    
    //const [media,setImg] = useState("");    

    const notify = (message) => toast(message);

    /***Navigation ***/
    const handleEdit = (phone) => {
        const dataToPass = { phone: phone };
        navigate(`/editphone/${phone.id}`, { state: { passedData: dataToPass } });
    };
    const handleToken = async () => {
        setSpinning((prev) => !prev);        
        axios.get(`${process.env.REACT_APP_API_URL}/phones/phone/token/${phone.id}`).then((response) => {
            setToken(response.data.token);            
            //setPhone( {...phone,token:response.data.token} );
            notify("Token generado correctamente!");           
        }).catch((error) => {
            console.log(error);
            notify("Error al generar token");           
        }).finally(() => {
            setSpinning((prev) => !prev);
        });
    };
    const handleNavigate = (chat) => {
        const dataToPass = { phone: phone, chat: chat };
        navigate(`/messages/${chat.id}`, { state: { passedData: dataToPass } });
    };
    const handleNavigateBack = () => {
        navigate(-1);
    }
    const getChats = (id) => {
        axios.get(`${process.env.REACT_APP_API_URL}/chats/${id}`).then((response) => {
            setChats(response.data);
        });
    }
    /***Navigation ***/

    useEffect(() => {
        const passedData = location.state.passedData;        
        if (passedData == null || passedData.phone == null) axios.get(`${process.env.REACT_APP_API_URL}/phones/phone/${pid}`).then((response) => {          
            setPhone(response.data);
            setToken(response.data.token);
            getChats(response.data.id);
        });
        else{
            setPhone(passedData.phone);
            setToken(passedData.phone.token);
            getChats(passedData.phone.id);            
        }
    }, [location,pid]);


    useEffect(() => {                        
        if(phone.status && phone.phoneNumber) {
            // Get socket instance
            const socketInstance = getSocket(authHeader);
            
            // Make sure we're connected
            if (!socketInstance.connected) {
                socketInstance.connect();
                console.log(`Connecting socket for phone ${phone.phoneNumber}`);
            }
            
            function onConnect() {
                console.log(`Socket connected! Emitting 'connected' for phone ${phone.phoneNumber}`);            
                setConnected(true);
                // Send phoneNumber property explicitly as expected by the server
                socketInstance.emit("connected", { 
                    phoneNumber: phone.phoneNumber 
                });
            }
            
            function onDisconnect() {
                console.log("Socket disconnected!");            
                setConnected(false);
                setQrVisible(false);
                setClientStatus("Disconnected from server");
            } 
            
            // Remove any existing listeners to prevent duplicates
            socketInstance.off('connect', onConnect);
            socketInstance.off('disconnect', onDisconnect);
            
            // Add listeners
            socketInstance.on('connect', onConnect);
            socketInstance.on('disconnect', onDisconnect);

            // If already connected, trigger the connect handler manually
            if (socketInstance.connected) {
                console.log("Socket already connected, triggering connect handler");
                onConnect();
            }
          
            return () => {
                console.log(`Cleaning up socket listeners for ${phone.phoneNumber}`);
                // Only remove event listeners, don't disconnect (other components may use it)
                socketInstance.off('connect', onConnect);
                socketInstance.off('disconnect', onDisconnect);
            };
        } else {
            setConnected(false);
            setClientStatus("Teléfono inactivo");
        }
    }, [phone, authHeader]);
    
    
    useEffect( () => {
        // Get socket instance
        const socketInstance = getSocket(authHeader);
        
        function onQr(data) {
            console.log("QR event received:", data);
            setClientStatus("Waiting to scan QR...");
            setReceivedId(data.id);            
            setQrVisible(true);            
            setQrCode(data.qr);              
        }
        
        function onReady(data) {                        
            console.log("Ready event received:", data);
            // Extract message from data object
            setClientStatus(data.message || "Ready!");
            setQrVisible(false);
        }

        function onClientState(data){
            console.log("Client state event:", data);
            if (data && data.message) {
                setClientStatus(data.message);
            } else {
                setClientStatus("Client state updated");
            }
        }

        function onAuthenticated(data) {
            console.log("Authenticated event:", data);
            setQrVisible(false);            
            setClientStatus("Authenticated on Whatsapp!");
        }

        function onChangedState(data){
            console.log("Changed state event:", data);
            if (data && data.message) {
                setClientStatus(data.message);
            } else {
                setClientStatus("State changed");
            }
        }

        function onError(data) {
            console.error("Socket error:", data);
            if (data && data.message) {
                setClientStatus(`Error: ${data.message}`);
                notify(`Error: ${data.message}`);
            }
        }

        // Remove any existing listeners to prevent duplicates
        socketInstance.off("qr");    
        socketInstance.off("ready");
        socketInstance.off("authenticated");
        socketInstance.off("changed_state");
        socketInstance.off("client_state");
        socketInstance.off("error");
        
        // Add new listeners
        socketInstance.on("qr", onQr);    
        socketInstance.on("ready", onReady);
        socketInstance.on("authenticated", onAuthenticated);
        socketInstance.on("changed_state", onChangedState);
        socketInstance.on("client_state", onClientState);
        socketInstance.on("error", onError);

        return () => {
            console.log("Cleaning up socket event listeners");
            socketInstance.off("qr", onQr);    
            socketInstance.off("ready", onReady);
            socketInstance.off("changed_state", onChangedState);
            socketInstance.off("client_state", onClientState);
            socketInstance.off("authenticated", onAuthenticated);
            socketInstance.off("error", onError);
        };
    }, [token, authHeader, notify]);

    const handleRestartSession = () => {
        setSpinning(true);
        const socketInstance = getSocket(authHeader);
        socketInstance.emit("restart_session", { phoneNumber: phone.phoneNumber });
        notify("Restarting WhatsApp session...");
    };

    useEffect(() => {
        const socketInstance = getSocket(authHeader);
        
        function onSessionRestarted(data) {
            setSpinning(false);
            notify(data.message);
            // The session will automatically reconnect through the normal flow
        }

        function onError(data) {
            setSpinning(false);
            notify(`Error: ${data.message}`);
        }

        // Remove any existing listeners to prevent duplicates
        socketInstance.off("session_restarted");
        socketInstance.off("error");
        
        // Add listeners
        socketInstance.on("session_restarted", onSessionRestarted);
        socketInstance.on("error", onError);

        return () => {
            socketInstance.off("session_restarted", onSessionRestarted);
            socketInstance.off("error", onError);
        };
    }, [authHeader, notify, phone.phoneNumber]);

    const handleFeatureToggle = async (feature, value) => {
        setPhone(prev => ({
            ...prev,
            [feature]: value
        }));
    };

    const handleFeatureChange = async (feature, value) => {
        setPhone(prev => ({
            ...prev,
            [feature]: value
        }));
    };

    const handleSaveFeatures = async () => {
        setIsSaving(true);
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/phones/phone/${phone.id}`, phone);
            if (response.status === 200) {
                notify("Features updated successfully!");
            }
        } catch (error) {
            console.error("Error saving features:", error);
            notify("Error saving features");
        } finally {
            setIsSaving(false);
        }
    };

  return (
    <div className='mt-5 container'>        
        <table className='table table-striped'>
            <caption className="d-flex justify-content-between align-items-center">
                <span>Detalles del teléfono</span>
                <div>
                    <button className="btn btn-sm btn-warning me-2"
                        onClick={handleRestartSession}
                        disabled={isSpinning}>
                        <i className={`material-icons ${isSpinning ? 'spin' : ''}`}>refresh</i>
                        Restart Session
                    </button>
                    <button className="btn btn-sm btn-success me-2"
                        onClick={() => handleEdit(phone)}>                            
                        <i className="material-icons">edit</i>
                    </button>
                    <button className="btn btn-sm btn-danger"
                        onClick={handleNavigateBack}>                            
                        <i className="material-icons">arrow_back</i>
                    </button>
                </div>
            </caption>
            <thead>
                <tr>
                    <th>Detalles del teléfono</th>
                    <th className='text-align-right'></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Id</td><td>{phone.id}</td>                    
                </tr>
                    <td>Client</td><td>{phone.client}</td>                    
                <tr>
                    <td>Number</td><td>{phone.phoneNumber}</td>
                </tr>                
                <tr>
                    <td>Activo</td><td>{phone.status ? <span>Activo<i className="material-icons">verified</i></span> : <span>Inactivo<i className="material-icons">highlight_off</i></span>}</td>
                </tr>                
                <tr>
                    <td>Phone Token</td><td>{token && token !== "" ? <CopyToClipboardButton  cssClass={''} textToCopy={`${token}`} label={`${token.slice(0,25)}...`} /> : '...'}<span className={`material-icons hand-pointer ${isSpinning ? 'spin' : ''}`} onClick={handleToken}>autorenew</span>refresh token</td>
                </tr>
                <tr>
                    <td>Status Whatsapp</td><td>{clientStatus ? <span>  {clientStatus} </span> : ''}</td>
                </tr>
                <tr>
                    <td>Conexión Backend</td><td>{isConnected ? <div className="led-light led-success"></div> : <div className="led-light led-danger"></div>}</td>
                </tr>

            </tbody>
        </table>
        <CopyToClipboardButton cssClass={'btn-warning'} textToCopy={`${currentUrl}/phoneQr/${phone.id}`} label={'Copy QR Only Route'} />
        <hr />
        <div className='container my-3'>            
                {qrVisible ? <figure className="figure"><QRCode value={qrCode}></QRCode><figcaption className="figure-caption">Qr for ID: {receivedId}</figcaption></figure>  : null}                            
        </div>
        <hr />

        {/* New Features Accordion */}
        <div className="accordion" id="featuresAccordion">
            {/* Notification Settings */}
            <div className="accordion-item">
                <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#notificationsCollapse">
                        Notification Settings
                    </button>
                </h2>
                <div id="notificationsCollapse" className="accordion-collapse collapse" data-bs-parent="#featuresAccordion">
                    <div className="accordion-body">
                        {/* Email Notifications */}
                        <div className="mb-3">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" 
                                    checked={phone.notifyEmail || false}
                                    onChange={(e) => handleFeatureToggle('notifyEmail', e.target.checked)} />
                                <label className="form-check-label">Enable Email Notifications</label>
                            </div>
                            <input type="email" className="form-control mt-2" 
                                value={phone.email || ''}
                                onChange={(e) => handleFeatureChange('email', e.target.value)}
                                placeholder="Email address" />
                        </div>

                        {/* Telegram Notifications */}
                        <div className="mb-3">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" 
                                    checked={phone.notifyTelegram || false}
                                    onChange={(e) => handleFeatureToggle('notifyTelegram', e.target.checked)} />
                                <label className="form-check-label">Enable Telegram Notifications</label>
                            </div>
                            <input type="text" className="form-control mt-2" 
                                value={phone.telegramChatId || ''}
                                onChange={(e) => handleFeatureChange('telegramChatId', e.target.value)}
                                placeholder="Telegram Chat ID" />
                        </div>

                        {/* WhatsApp Notifications */}
                        <div className="mb-3">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" 
                                    checked={phone.notifyWhatsapp || false}
                                    onChange={(e) => handleFeatureToggle('notifyWhatsapp', e.target.checked)} />
                                <label className="form-check-label">Enable WhatsApp Notifications</label>
                            </div>
                            <input type="text" className="form-control mt-2" 
                                value={phone.notifyWhatsappNumber || ''}
                                onChange={(e) => handleFeatureChange('notifyWhatsappNumber', e.target.value)}
                                placeholder="WhatsApp number for notifications" />
                        </div>
                    </div>
                </div>
            </div>

            {/* Chatwoot Integration */}
            <div className="accordion-item">
                <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#chatwootCollapse">
                        Chatwoot Integration
                    </button>
                </h2>
                <div id="chatwootCollapse" className="accordion-collapse collapse" data-bs-parent="#featuresAccordion">
                    <div className="accordion-body">
                        <div className="form-check form-switch mb-3">
                            <input className="form-check-input" type="checkbox" 
                                checked={phone.useChatwoot || false}
                                onChange={(e) => handleFeatureToggle('useChatwoot', e.target.checked)} />
                            <label className="form-check-label">Enable Chatwoot Integration</label>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Chatwoot URL</label>
                            <input type="text" className="form-control" 
                                value={phone.chatwootUrl || ''}
                                onChange={(e) => handleFeatureChange('chatwootUrl', e.target.value)} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Webhook URL</label>
                            <input type="text" className="form-control" 
                                value={phone.chatwootWebhookUrl || ''}
                                onChange={(e) => handleFeatureChange('chatwootWebhookUrl', e.target.value)} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Account ID</label>
                            <input type="text" className="form-control" 
                                value={phone.chatwootAccountId || ''}
                                onChange={(e) => handleFeatureChange('chatwootAccountId', e.target.value)} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Inbox ID</label>
                            <input type="text" className="form-control" 
                                value={phone.chatwootInboxId || ''}
                                onChange={(e) => handleFeatureChange('chatwootInboxId', e.target.value)} />
                        </div>
                    </div>
                </div>
            </div>

            {/* AI Agent */}
            <div className="accordion-item">
                <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#aiAgentCollapse">
                        AI Agent
                    </button>
                </h2>
                <div id="aiAgentCollapse" className="accordion-collapse collapse" data-bs-parent="#featuresAccordion">
                    <div className="accordion-body">
                        <div className="form-check form-switch mb-3">
                            <input className="form-check-input" type="checkbox" 
                                checked={phone.useAIAgent || false}
                                onChange={(e) => handleFeatureToggle('useAIAgent', e.target.checked)} />
                            <label className="form-check-label">Enable AI Agent</label>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Webhook URL</label>
                            <input type="text" className="form-control" 
                                value={phone.aiAgentWebhookUrl || ''}
                                onChange={(e) => handleFeatureChange('aiAgentWebhookUrl', e.target.value)} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Phone Filter (comma-separated)</label>
                            <input type="text" className="form-control" 
                                value={phone.aiAgentPhoneFilter || ''}
                                onChange={(e) => handleFeatureChange('aiAgentPhoneFilter', e.target.value)} />
                        </div>
                    </div>
                </div>
            </div>

            {/* Ecommerce Agent */}
            <div className="accordion-item">
                <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ecommerceCollapse">
                        Ecommerce Agent
                    </button>
                </h2>
                <div id="ecommerceCollapse" className="accordion-collapse collapse" data-bs-parent="#featuresAccordion">
                    <div className="accordion-body">
                        <div className="form-check form-switch mb-3">
                            <input className="form-check-input" type="checkbox" 
                                checked={phone.useEcommerceAgent || false}
                                onChange={(e) => handleFeatureToggle('useEcommerceAgent', e.target.checked)} />
                            <label className="form-check-label">Enable Ecommerce Agent</label>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Ecommerce URL</label>
                            <input type="text" className="form-control" 
                                value={phone.ecommerceUrl || ''}
                                onChange={(e) => handleFeatureChange('ecommerceUrl', e.target.value)} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Webhook URL</label>
                            <input type="text" className="form-control" 
                                value={phone.ecommerceWebhookUrl || ''}
                                onChange={(e) => handleFeatureChange('ecommerceWebhookUrl', e.target.value)} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Phone Filter (comma-separated)</label>
                            <input type="text" className="form-control" 
                                value={phone.ecommercePhoneFilter || ''}
                                onChange={(e) => handleFeatureChange('ecommercePhoneFilter', e.target.value)} />
                        </div>
                    </div>
                </div>
            </div>

            {/* Receipts Analysis */}
            <div className="accordion-item">
                <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#receiptsCollapse">
                        Receipts Analysis
                    </button>
                </h2>
                <div id="receiptsCollapse" className="accordion-collapse collapse" data-bs-parent="#featuresAccordion">
                    <div className="accordion-body">
                        <div className="form-check form-switch mb-3">
                            <input className="form-check-input" type="checkbox" 
                                checked={phone.useReceiptsAnalysis || false}
                                onChange={(e) => handleFeatureToggle('useReceiptsAnalysis', e.target.checked)} />
                            <label className="form-check-label">Enable Receipts Analysis</label>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Webhook URL</label>
                            <input type="text" className="form-control" 
                                value={phone.receiptsWebhookUrl || ''}
                                onChange={(e) => handleFeatureChange('receiptsWebhookUrl', e.target.value)} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Phone Filter (comma-separated)</label>
                            <input type="text" className="form-control" 
                                value={phone.receiptsPhoneFilter || ''}
                                onChange={(e) => handleFeatureChange('receiptsPhoneFilter', e.target.value)} />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* Save Features Button */}
        <div className="d-flex justify-content-end mt-3 mb-3">
            <button 
                className="btn btn-primary" 
                onClick={handleSaveFeatures}
                disabled={isSaving}>
                <i className={`material-icons ${isSaving ? 'spin' : ''}`}>save</i>
                {isSaving ? ' Saving...' : ' Save Features'}
            </button>
        </div>

        <hr />
        <div>
            <table className='table table-striped'>
                <caption>Chats</caption>
                <thead>
                    <tr>
                        <th>Chat</th>
                        <th>Tipo</th>                        
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                {chats.map((value,key) => {
                    return <tr key={key}>
                        <td>{value.chatName}</td>
                        <td>{value.type} {value.type==="group" && <CopyToClipboardButton cssClass={'btn-link'} textToCopy={`${value.chatId}`} label={'Copy Group ID'} />}</td>
                        <td>                            
                            <button className="btn btn-sm btn-primary"
                            onClick={() => {handleNavigate(value)}}>
                                <i className="material-icons">preview</i>
                            </button>
                        </td>
                    </tr>                    
                })}                    
                </tbody>
            </table>
        </div>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"                
        />
    </div>
    
  )
}

export default Phone