import React from 'react'
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';

function CreatePhones() {
    const authHeader = useAuthHeader();    
    axios.defaults.headers.common = {'Authorization': `${authHeader}`};
    let navigate = useNavigate();

    const notify = () => toast("Se creó el teléfono correctamente!");

    const initialValues = {
        client: "",
        phoneNumber: "",
        wid: "",
        status: true,
        // Notification settings
        notifyEmail: false,
        email: "",
        notifyTelegram: false,
        telegramChatId: "",
        notifyWhatsapp: false,
        notifyWhatsappNumber: "",
        // Chatwoot Integration
        useChatwoot: false,
        chatwootUrl: "",
        chatwootWebhookUrl: "",
        chatwootAccountId: "",
        chatwootInboxId: "",
        // AI Agent
        useAIAgent: false,
        aiAgentWebhookUrl: "",
        aiAgentPhoneFilter: "",
        // Ecommerce Agent
        useEcommerceAgent: false,
        ecommerceUrl: "",
        ecommerceWebhookUrl: "",
        ecommercePhoneFilter: "",
        // Receipts Analysis
        useReceiptsAnalysis: false,
        receiptsWebhookUrl: "",
        receiptsPhoneFilter: ""
    };

    const validationSchema = Yup.object().shape({
        client: Yup.string().min(3).max(200).required(),
        phoneNumber: Yup.number().required(),        
        status: Yup.bool(),
        email: Yup.string().email().nullable(),
        notifyWhatsappNumber: Yup.string().nullable(),
        chatwootUrl: Yup.string().url().nullable(),
        chatwootWebhookUrl: Yup.string().url().nullable(),
        aiAgentWebhookUrl: Yup.string().url().nullable(),
        ecommerceUrl: Yup.string().url().nullable(),
        ecommerceWebhookUrl: Yup.string().url().nullable(),
        receiptsWebhookUrl: Yup.string().url().nullable()
    });

    const onSubmit = (data) => {
        console.log(data);
        axios.post(`${process.env.REACT_APP_API_URL}/phones/phone`, data).then((response) => {
            console.log("phone saved:"+response);
            notify();
            setTimeout(() => {
                navigate('/');
            }, 300);
        })
    };

    return (
        <div className="container mt-5 pt5">        
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                <Form>
                    <input id="inputCreatePost" type="hidden" name="id" value="0" />
                    
                    {/* Basic Information */}
                    <div className="card mb-4">
                        <div className="card-header">
                            <h5 className="mb-0">Basic Information</h5>
                        </div>
                        <div className="card-body">
                            <div className='form-group mb-3'>
                                <label>Cliente o Usuario</label>
                                <ErrorMessage name="client" component="span" className="text-danger" />
                                <Field id="inputCreatePost" className='form-control' name="client" autocomplete="off" placeholder="Nombre Usuario" />
                            </div>
                            <div className='form-group mb-3'>
                                <label>Número de teléfono</label>
                                <ErrorMessage name="phoneNumber" component="span" className="text-danger" />
                                <Field id="inputCreatePost" className='form-control' name="phoneNumber" autocomplete="off" placeholder="Número sin + con codigo de país ej. 50688884444" />
                            </div>
                            <div className='form-group'>
                                <label className='form-check-label'>Activo</label>
                                <ErrorMessage name="status" component="span" className="text-danger" />
                                <Field className='form-check-input ms-2' type="checkbox" id="inputCreatePost" name="status" />                    
                            </div>
                        </div>
                    </div>

                    {/* Notification Settings */}
                    <div className="card mb-4">
                        <div className="card-header">
                            <h5 className="mb-0">Notification Settings</h5>
                        </div>
                        <div className="card-body">
                            {/* Email Notifications */}
                            <div className="mb-3">
                                <div className="form-check form-switch">
                                    <Field className="form-check-input" type="checkbox" name="notifyEmail" />
                                    <label className="form-check-label">Enable Email Notifications</label>
                                </div>
                                <Field className="form-control mt-2" name="email" placeholder="Email address" />
                            </div>

                            {/* Telegram Notifications */}
                            <div className="mb-3">
                                <div className="form-check form-switch">
                                    <Field className="form-check-input" type="checkbox" name="notifyTelegram" />
                                    <label className="form-check-label">Enable Telegram Notifications</label>
                                </div>
                                <Field className="form-control mt-2" name="telegramChatId" placeholder="Telegram Chat ID" />
                            </div>

                            {/* WhatsApp Notifications */}
                            <div className="mb-3">
                                <div className="form-check form-switch">
                                    <Field className="form-check-input" type="checkbox" name="notifyWhatsapp" />
                                    <label className="form-check-label">Enable WhatsApp Notifications</label>
                                </div>
                                <Field className="form-control mt-2" name="notifyWhatsappNumber" placeholder="WhatsApp number for notifications" />
                            </div>
                        </div>
                    </div>

                    {/* Chatwoot Integration */}
                    <div className="card mb-4">
                        <div className="card-header">
                            <h5 className="mb-0">Chatwoot Integration</h5>
                        </div>
                        <div className="card-body">
                            <div className="form-check form-switch mb-3">
                                <Field className="form-check-input" type="checkbox" name="useChatwoot" />
                                <label className="form-check-label">Enable Chatwoot Integration</label>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Chatwoot URL</label>
                                <Field className="form-control" name="chatwootUrl" placeholder="https://your-chatwoot.com" />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Webhook URL</label>
                                <Field className="form-control" name="chatwootWebhookUrl" placeholder="https://webhook-url" />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Account ID</label>
                                <Field className="form-control" name="chatwootAccountId" placeholder="Account ID" />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Inbox ID</label>
                                <Field className="form-control" name="chatwootInboxId" placeholder="Inbox ID" />
                            </div>
                        </div>
                    </div>

                    {/* AI Agent */}
                    <div className="card mb-4">
                        <div className="card-header">
                            <h5 className="mb-0">AI Agent</h5>
                        </div>
                        <div className="card-body">
                            <div className="form-check form-switch mb-3">
                                <Field className="form-check-input" type="checkbox" name="useAIAgent" />
                                <label className="form-check-label">Enable AI Agent</label>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Webhook URL</label>
                                <Field className="form-control" name="aiAgentWebhookUrl" placeholder="https://webhook-url" />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Phone Filter (comma-separated)</label>
                                <Field className="form-control" name="aiAgentPhoneFilter" placeholder="50688884444, 50677776666" />
                            </div>
                        </div>
                    </div>

                    {/* Ecommerce Agent */}
                    <div className="card mb-4">
                        <div className="card-header">
                            <h5 className="mb-0">Ecommerce Agent</h5>
                        </div>
                        <div className="card-body">
                            <div className="form-check form-switch mb-3">
                                <Field className="form-check-input" type="checkbox" name="useEcommerceAgent" />
                                <label className="form-check-label">Enable Ecommerce Agent</label>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Ecommerce URL</label>
                                <Field className="form-control" name="ecommerceUrl" placeholder="https://your-ecommerce.com" />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Webhook URL</label>
                                <Field className="form-control" name="ecommerceWebhookUrl" placeholder="https://webhook-url" />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Phone Filter (comma-separated)</label>
                                <Field className="form-control" name="ecommercePhoneFilter" placeholder="50688884444, 50677776666" />
                            </div>
                        </div>
                    </div>

                    {/* Receipts Analysis */}
                    <div className="card mb-4">
                        <div className="card-header">
                            <h5 className="mb-0">Receipts Analysis</h5>
                        </div>
                        <div className="card-body">
                            <div className="form-check form-switch mb-3">
                                <Field className="form-check-input" type="checkbox" name="useReceiptsAnalysis" />
                                <label className="form-check-label">Enable Receipts Analysis</label>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Webhook URL</label>
                                <Field className="form-control" name="receiptsWebhookUrl" placeholder="https://webhook-url" />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Phone Filter (comma-separated)</label>
                                <Field className="form-control" name="receiptsPhoneFilter" placeholder="50688884444, 50677776666" />
                            </div>
                        </div>
                    </div>

                    <button className='btn btn-primary'>Create Phone</button>
                    <ToastContainer />
                </Form>
            </Formik>
        </div>
    )
}

export default CreatePhones