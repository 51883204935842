import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';

function EditPhones() {
    const authHeader = useAuthHeader();
    axios.defaults.headers.common = {'Authorization': `${authHeader}`};
    const location = useLocation();
    let [action,setAction] = useState("Edit");
    let [phone,setPhone] = useState({
        id:0,
        client: "",
        phoneNumber:"",
        wid:"",
        status:true,
        notifyEmail: false,
        email: "",
        notifyTelegram: false,
        telegramChatId: "",
        notifyWhatsapp: false,
        notifyWhatsappNumber: "",
    });
    const notify = () => toast("Se guardó el teléfono correctamente!");                
    
    let navigate = useNavigate();
    let { id } = useParams();

    const handleNavigateBack = () => {        
        navigate(-1);
    };
    
    const onSubmit = (e) => {        
        e.preventDefault()
        console.log(phone);
        axios.post(`${process.env.REACT_APP_API_URL}/phones/phone`,phone).then((response) => {
            console.log("phone saved:"+ response);
            notify();
            
          })
    };    
    useEffect( () => {          
        setAction("Update");
        const passedData = location.state?.passedData.phone;      
        if (passedData == null ){                    
            axios.get(`${process.env.REACT_APP_API_URL}/phones/phone/${id}`).then((response) => {
                console.log(response);                
                setPhone(response.data);                                      
            })        
        }
        else{
            setPhone(passedData);                                 
        }
        
        
    }, [location.state.passedData.phone, id]);	

  return (
    <div className="container mt-5">
            <button className='btn btn-small btn-light' onClick={handleNavigateBack}><span className="material-icons">arrow_back</span></button>
            <h1 className='text-left'>{action} Phone</h1>                
            <form onSubmit={onSubmit}>
                <input id="inputCreatePost" type="hidden" name="id" value={phone.id} onChange={e => setPhone( {...phone,id:e.target.value} )} />
                <div className='form-group'>
                    <label>Cliente o Usuario</label>
                    {/* <ErrorMessage name="client" component="span" /> */}
                    <input id="inputCreatePost" className='form-control' name="client" autoComplete="off"  placeholder="Nombre Usuario" value={phone.client} onChange={e => setPhone( {...phone,client:e.target.value} )} />
                    
      
                </div>
                <div className='form-group'>
                    <label>Número de teléfono</label>
                    {/* <ErrorMessage name="phoneNumber" component="span" /> */}
                    <input id="inputCreatePost" className='form-control' name="phoneNumber" autoComplete="off" placeholder="Número sin + con codigo de país ej. 50688884444" value={phone.phoneNumber} onChange={e => setPhone( {...phone,phoneNumber:e.target.value} )} />
                    
                </div>
                <div className='form-group'>
                    <label className='form-check-label'>Activo</label>
                    {/* <ErrorMessage name="status" component="span" /> */}
                    <input className='form-check-input' type="checkbox" id="inputCreatePost" name="status" checked={phone.status} onChange={e => setPhone( {...phone,status:e.target.checked} )}/>                    
                </div>

                {/* Notification Settings Section */}
                <div className='card mt-4 mb-4'>
                    <div className='card-header'>
                        <h4>Notificaciones de Desconexión</h4>
                        <small className='text-muted'>Configure las notificaciones cuando este teléfono se desconecte</small>
                    </div>
                    <div className='card-body'>
                        {/* Email Notifications */}
                        <div className='form-group mb-3'>
                            <div className='form-check'>
                                <input 
                                    className='form-check-input' 
                                    type="checkbox" 
                                    id="notifyEmail" 
                                    name="notifyEmail" 
                                    checked={phone.notifyEmail} 
                                    onChange={e => setPhone({...phone, notifyEmail: e.target.checked})}
                                />
                                <label className='form-check-label' htmlFor="notifyEmail">
                                    Notificar por Email
                                </label>
                            </div>
                            {phone.notifyEmail && (
                                <div className='mt-2'>
                                    <input 
                                        className='form-control' 
                                        type="email" 
                                        placeholder="Email para notificaciones" 
                                        value={phone.email || ''} 
                                        onChange={e => setPhone({...phone, email: e.target.value})}
                                    />
                                </div>
                            )}
                        </div>

                        {/* Telegram Notifications */}
                        <div className='form-group mb-3'>
                            <div className='form-check'>
                                <input 
                                    className='form-check-input' 
                                    type="checkbox" 
                                    id="notifyTelegram" 
                                    name="notifyTelegram" 
                                    checked={phone.notifyTelegram} 
                                    onChange={e => setPhone({...phone, notifyTelegram: e.target.checked})}
                                />
                                <label className='form-check-label' htmlFor="notifyTelegram">
                                    Notificar por Telegram
                                </label>
                            </div>
                            {phone.notifyTelegram && (
                                <div className='mt-2'>
                                    <input 
                                        className='form-control' 
                                        type="text" 
                                        placeholder="Telegram Chat ID" 
                                        value={phone.telegramChatId || ''} 
                                        onChange={e => setPhone({...phone, telegramChatId: e.target.value})}
                                    />
                                    <small className='text-muted'>Puede obtener su Chat ID desde @userinfobot en Telegram</small>
                                </div>
                            )}
                        </div>

                        {/* WhatsApp Notifications */}
                        <div className='form-group mb-3'>
                            <div className='form-check'>
                                <input 
                                    className='form-check-input' 
                                    type="checkbox" 
                                    id="notifyWhatsapp" 
                                    name="notifyWhatsapp" 
                                    checked={phone.notifyWhatsapp} 
                                    onChange={e => setPhone({...phone, notifyWhatsapp: e.target.checked})}
                                />
                                <label className='form-check-label' htmlFor="notifyWhatsapp">
                                    Notificar por WhatsApp
                                </label>
                            </div>
                            {phone.notifyWhatsapp && (
                                <div className='mt-2'>
                                    <input 
                                        className='form-control' 
                                        type="text" 
                                        placeholder="Número de WhatsApp (formato: 50688889999)" 
                                        value={phone.notifyWhatsappNumber || ''} 
                                        onChange={e => setPhone({...phone, notifyWhatsappNumber: e.target.value})}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <button type='submit' className='btn btn-primary'>{action} Phone</button>
                <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"                
                 />
            </form>        
    </div>
  )
}

export default EditPhones